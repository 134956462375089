// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-educativo-js": () => import("./../../../src/pages/educativo.js" /* webpackChunkName: "component---src-pages-educativo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privado-js": () => import("./../../../src/pages/privado.js" /* webpackChunkName: "component---src-pages-privado-js" */),
  "component---src-pages-salud-js": () => import("./../../../src/pages/salud.js" /* webpackChunkName: "component---src-pages-salud-js" */),
  "component---src-pages-sector-educativo-bibliografia-impresa-js": () => import("./../../../src/pages/sector-educativo/bibliografia-impresa.js" /* webpackChunkName: "component---src-pages-sector-educativo-bibliografia-impresa-js" */),
  "component---src-pages-sector-educativo-bibliotecas-digitales-js": () => import("./../../../src/pages/sector-educativo/bibliotecas-digitales.js" /* webpackChunkName: "component---src-pages-sector-educativo-bibliotecas-digitales-js" */),
  "component---src-pages-sector-educativo-educacion-continua-js": () => import("./../../../src/pages/sector-educativo/educacion-continua.js" /* webpackChunkName: "component---src-pages-sector-educativo-educacion-continua-js" */),
  "component---src-pages-sector-educativo-educacion-especial-js": () => import("./../../../src/pages/sector-educativo/educacion-especial.js" /* webpackChunkName: "component---src-pages-sector-educativo-educacion-especial-js" */),
  "component---src-pages-sector-educativo-libros-electronicos-js": () => import("./../../../src/pages/sector-educativo/libros-electronicos.js" /* webpackChunkName: "component---src-pages-sector-educativo-libros-electronicos-js" */),
  "component---src-pages-sector-educativo-material-de-educacion-inicial-js": () => import("./../../../src/pages/sector-educativo/material-de-educacion-inicial.js" /* webpackChunkName: "component---src-pages-sector-educativo-material-de-educacion-inicial-js" */),
  "component---src-pages-sector-educativo-multimedia-js": () => import("./../../../src/pages/sector-educativo/multimedia.js" /* webpackChunkName: "component---src-pages-sector-educativo-multimedia-js" */),
  "component---src-pages-sector-educativo-programa-de-ingles-js": () => import("./../../../src/pages/sector-educativo/programa-de-ingles.js" /* webpackChunkName: "component---src-pages-sector-educativo-programa-de-ingles-js" */),
  "component---src-pages-sector-educativo-proyectos-editoriales-js": () => import("./../../../src/pages/sector-educativo/proyectos-editoriales.js" /* webpackChunkName: "component---src-pages-sector-educativo-proyectos-editoriales-js" */),
  "component---src-pages-sector-educativo-revistas-js": () => import("./../../../src/pages/sector-educativo/revistas.js" /* webpackChunkName: "component---src-pages-sector-educativo-revistas-js" */),
  "component---src-pages-sector-educativo-sistemas-de-informacion-js": () => import("./../../../src/pages/sector-educativo/sistemas-de-informacion.js" /* webpackChunkName: "component---src-pages-sector-educativo-sistemas-de-informacion-js" */),
  "component---src-pages-sector-educativo-soluciones-de-comunicacion-js": () => import("./../../../src/pages/sector-educativo/soluciones-de-comunicacion.js" /* webpackChunkName: "component---src-pages-sector-educativo-soluciones-de-comunicacion-js" */),
  "component---src-pages-sector-educativo-tecnologia-educativa-js": () => import("./../../../src/pages/sector-educativo/tecnologia-educativa.js" /* webpackChunkName: "component---src-pages-sector-educativo-tecnologia-educativa-js" */),
  "component---src-pages-sector-privado-bibliotecas-digitales-js": () => import("./../../../src/pages/sector-privado/bibliotecas-digitales.js" /* webpackChunkName: "component---src-pages-sector-privado-bibliotecas-digitales-js" */),
  "component---src-pages-sector-privado-libros-electronicos-js": () => import("./../../../src/pages/sector-privado/libros-electronicos.js" /* webpackChunkName: "component---src-pages-sector-privado-libros-electronicos-js" */),
  "component---src-pages-sector-privado-revistas-js": () => import("./../../../src/pages/sector-privado/revistas.js" /* webpackChunkName: "component---src-pages-sector-privado-revistas-js" */),
  "component---src-pages-sector-privado-sistemas-de-informacion-js": () => import("./../../../src/pages/sector-privado/sistemas-de-informacion.js" /* webpackChunkName: "component---src-pages-sector-privado-sistemas-de-informacion-js" */),
  "component---src-pages-sector-privado-tecnologia-educativa-js": () => import("./../../../src/pages/sector-privado/tecnologia-educativa.js" /* webpackChunkName: "component---src-pages-sector-privado-tecnologia-educativa-js" */),
  "component---src-pages-sector-salud-bibliotecas-digitales-js": () => import("./../../../src/pages/sector-salud/bibliotecas-digitales.js" /* webpackChunkName: "component---src-pages-sector-salud-bibliotecas-digitales-js" */),
  "component---src-pages-sector-salud-libros-electronicos-js": () => import("./../../../src/pages/sector-salud/libros-electronicos.js" /* webpackChunkName: "component---src-pages-sector-salud-libros-electronicos-js" */),
  "component---src-pages-sector-salud-revistas-js": () => import("./../../../src/pages/sector-salud/revistas.js" /* webpackChunkName: "component---src-pages-sector-salud-revistas-js" */),
  "component---src-pages-sector-salud-sistemas-de-informacion-js": () => import("./../../../src/pages/sector-salud/sistemas-de-informacion.js" /* webpackChunkName: "component---src-pages-sector-salud-sistemas-de-informacion-js" */),
  "component---src-pages-sector-salud-tecnologia-educativa-js": () => import("./../../../src/pages/sector-salud/tecnologia-educativa.js" /* webpackChunkName: "component---src-pages-sector-salud-tecnologia-educativa-js" */),
  "component---src-pages-sectores-js": () => import("./../../../src/pages/sectores.js" /* webpackChunkName: "component---src-pages-sectores-js" */)
}

